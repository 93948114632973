main {
  padding: 3rem 0.875rem;
  display: flex;
  flex-direction: column;
}

main > section {
  margin-bottom: 1.875rem;
}

.input__value,
.total__weight h2,
.weight__balance__value {
  font-family: 'Share Tech Mono', monospace;
}

.section__title {
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1rem;
  color: #efefef;
  margin: 1.25rem 0 0.75rem;
}

.top__inputs .grid__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 0.5rem 1.25rem;
  justify-items: center;
}

.front__title {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.front__left {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.car__body {
  grid-column: 2 / 3;
  grid-row: 2 / 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.car__body img {
  height: 250px;
}

.front__right {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.rear__left {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
}

.rear__title {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
}

.rear__right {
  grid-column: 3 / 4;
  grid-row: 5 / 6;
}

input {
  text-align: center;
}

.input__container {
  margin: 0.5rem 0;
  text-align: center;
}

.input__value {
  margin: 0.625rem 0;
  color: white;
  border: 1px solid #2c3ea1;
  max-width: 80px;
  font-size: 1.25rem;
  border-radius: 4px;
  padding: 0.625rem;
}

.input__value:focus {
  /* border-color: #4862f7; */
  border-color: #1abc9c;
}

.label,
.total__weight h5 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #dedede;
  text-transform: capitalize;
}

.total__weight {
  text-align: center;
}

.total__weight h2 {
  font-size: 2.5rem;
  color: #fefefe;
  margin: 0.625rem 0;
}

.weight__balance {
  margin: 2rem 0 1.25rem;
  text-align: center;
}

.weight__balance .grid__container,
.diagonal__balance .grid__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem 1.25rem;
  justify-items: center;
}

.weight__balance__value {
  font-size: 1.5rem;
  color: #dfdfdf;
  margin: 0.625rem 0;
}

.weight__balance__percentage {
  font-size: 3rem;
  color: white;
}

.diagonal__balance {
  text-align: center;
}

.error-msg {
  font-size: 0.8125rem;
  color: red;
  line-height: 1.2rem;
  display: inline-block;
}

button {
  border: none;
  outline: none;
}

button:hover {
  cursor: pointer;
}

.btn {
  padding: 0.875rem 1.5rem;
  margin: 1.25rem 0;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  border-radius: 4px;
}

.full-width {
  width: 100%;
}

.submit {
  background: #3e92cc;
  color: white;
}

.reset {
  background: #00cec9;
  color: #02514f;
}
