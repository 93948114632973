:root {
  --dark-bg: #252941;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: var(--dark-bg);
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
}

input {
  -webkit-appearance: none;
  border: none;
  background: none;
}

input:focus {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

/* Hide arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
